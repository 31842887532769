import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

export default function Page({ data: { page } }) {
  const { history } = page;
  return (
    <Layout>
      <Seo seo={page.seo} />
      <div className="page__featured-image__wrapper ">
        <img
          className="page__featured-image"
          src={page?.featuredImage?.node?.sourceUrl}
          alt=""
        />
        <h2 className="page__title">{page.title}</h2>
      </div>
      <div className="wysiwyg__content__wrapper pt-6 pb-10">
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </div>
      <div className="wysiwyg__content__wrapper pb-20">
        {history.event?.map((event, index) => (
          <div key={index} className="history__item">
            <img
              height="20rem"
              width="20rem"
              className="history__item__img"
              alt=""
              src={event.image.sourceUrl}
            />
            <div>
              <p className="history__item__title">
                <span>{event.years}</span> {event.title && `- ${event.title}`}
              </p>
              <p
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    page: wpPage(slug: { eq: "history" }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      content
      seo {
        fullHead
      }
      history {
        event {
          title
          years
          description
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;
